import React, { useContext } from 'react'
import { navigate } from 'gatsby'

import CustomQuoteLayout from '../../../views/custom-quote/components/CustomQuoteLayout'
import FormNavigation from '../../../views/custom-quote/components/FormNavigation'
import FormQuestion from '../../../views/custom-quote/components/FormQuestion'

import { CustomQuoteContext } from '../../../views/custom-quote/context/CustomQuoteManager'
import { CUSTOM_QUOTE_SEQUENCE_LINKS } from '../../../views/custom-quote/constants'
import FormSelectDate from '../../../views/custom-quote/components/FormSelectDate'

const Deadline = () => {
  const { formData, updateFormData } = useContext(CustomQuoteContext)

  const handleSelect = item => {
    updateFormData({
      ...formData,
      deadline: item,
    })
  }

  const handleSubmit = () => {
    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.deadline.next)
  }

  return (
    <CustomQuoteLayout>
      <FormQuestion
        title="When do you need this by?"
      />
      <FormSelectDate
        selected={formData.deadline}
        onChange={handleSelect}
      />
      <FormNavigation
        onSubmit={handleSubmit}
        previousLink={CUSTOM_QUOTE_SEQUENCE_LINKS.deadline.previous}
      />
    </CustomQuoteLayout>
  )
}

export default Deadline
