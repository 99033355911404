import React from 'react'
import { css } from 'styled-components'
import ReactDatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { space, palette, colors } from '../../../utils/tokens'

const FormSelectDate = ({ selected, onChange }) => {

  return (
    <div
      css={css({
        '.react-datepicker': {
          fontFamily: `inherit`,
        },
        '.react-datepicker__header': {
          backgroundColor: colors.primaryBrand,
        },
        '.react-datepicker__day--selected': {
          backgroundColor: colors.primaryBrand,
        },
        '.react-datepicker__day-name': {
          color: colors.white,
        },
        '.react-datepicker__current-month': {
          color: colors.white,
        }
      })}
    >
      <ReactDatePicker
        selected={selected}
        onChange={onChange}
        minDate={new Date()}
        placeholderText="Click to select a date"
        css={css({
          borderRadius: `${space[1]}px`,
          border: `1px solid ${palette.grey[50]}`,
          padding: `${space[2]}px`,
          fontWeight: `bold`,
          height: `48px`,
          width: `100%`,
          resize: `none`,
        })}
      />
    </div>
  )
}

export default FormSelectDate
